import React from "react";
import Page from "../components/Layout/Page";

const NotFoundPage = () => (
    <Page title="404 Not Found" className={"flex justify-center items-center"}>
        <div className={"text-5xl font-hairline font-sans"}>
            You just hit a route that doesn&#39;t exist... the sadness.
        </div>
    </Page>
);

export default NotFoundPage;
